var activation = {
	register: function(){
		var self = this;

		ui.showLoadScreen();
		ui.showQueue(0);

		$.ajax({
			url: '/activation/register',
			data: {
				installationId: [
					$('#tb0').val(),
					$('#tb1').val(),
					$('#tb2').val(),
					$('#tb3').val(),
					$('#tb4').val(),
					$('#tb5').val(),
					$('#tb6').val(),
					$('#tb7').val(),
					$('#tb8').val()
				]
			},
			success: function(data){
				self.execute();
			}
		});
	},
	execute: function(){
		var self = this;

		$.ajax({
			url: '/activation/execute',
			success: function(data){
				if(data.status == 'ready_to_execute'){
					ui.hideQueue();
					ui.showValidating();
					ui.btnValidating();

					self.execute();
				}
				else if(data.status == 'succeed'){
					ui.btnValidated();
					ui.hideLoadScreen();
					ui.switchToResult();
					var result = data;
					$('.confirmation-id-result__digits').html(result.confirmation_id);
					$('body').css('overflow-y', 'auto');
				}
				else if(data.status == 'failed'){
					$('.green-btn-progress').stop();
					$('.load-screen__head').removeClass('active');
					$('.load-screen__validating').removeClass('active');
					$('.load-screen__queue').removeClass('active');
					$('.load-screen__error').addClass('active');
					$('.load-screen__error .action-title').html(data.error_message);
				}
				else {
					ui.showQueue(data.queue_number);

					setTimeout(function(){
						self.execute();
					}, 3000);
				}	
			}
		});
	}
};

var ui = {
	init: function(){
		$(document).ready(function(){
			var $digitsNum = $('#digits').data('max-digits');
			var $digitsInput = $('#digits .digits-input');
			var $digitsCheck = 0;
			var $digitsLength = 0;
			
			$('.btn-digits-submit').click(function(){
				$('.small-warning-numbers').removeClass('active');
				$.each($digitsInput, function() {
					var $input = $(this);
					var $inputNumeric = $.isNumeric($input.val());
					var $inputVal = $input.val().length;
					//console.log($inputVal);
					
					if (($inputNumeric === true) && ($inputVal > 0)) {
						$digitsCheck += 1;
						
						if ($inputVal === $digitsNum) {
							$digitsLength += 1;
						}
						
					} else if ($inputNumeric === false) {						
						
						if ($inputVal === $digitsNum) {
							$('.small-warning-numbers').addClass('active');
							$input.addClass('not-filled');
							
						}
					}
				
				});
				
				if($digitsCheck === 9 && $digitsLength === 9){
					activation.register();
				}

				$digitsCheck = 0;
				$digitsLength = 0;
			
			});

		});
	},
	showLoadScreen: function(){
		$('.load-screen').addClass('active');
	},
	showQueue: function(queueNumber){
		$('.load-screen__queue').addClass('active');
		$('.digits-input').removeClass('not-filled');
		$('.small-warning').removeClass('active');
		$('.small-warning-numbers').removeClass('active');

		if(queueNumber > 0){
			$('.load-screen__queue .queue-info').addClass('active');
			$('.load-screen__queue .queue-info .queue-number').html(queueNumber);
		}
	},
	hideQueue: function(){
		$('.load-screen__queue').removeClass('active');
		$('.load-screen__queue .queue-info').removeClass('active');
	},
	hideLoadScreen: function() {
		setTimeout(function() {
			$('.load-screen').removeClass('active');
		}, 500);
	},
	switchToResult: function() {
		setTimeout(function() {
			var $input = $('.digits-input');
			$('.page-title').addClass('result');
			$input.addClass('result').prop('disabled', 'disabled');
			$('.btn-digits-submit').addClass('hidden');
			$('.btn--prim.success').addClass('active');
			$('.btn--prim.success').addClass('active');
			$('.confirmation-id-result-container').addClass('active');
			$('.load-screen').hide(300);
		}, 500);
	},
	showValidating: function(){
		$('.load-screen__validating').addClass('active');
	},
	btnValidating: function(){
		var $greenBar = $('.green-btn-progress');
		//var $greenBarWidth = $greenBar.css('width');
		
		$greenBar.animate({'width': '100%'}, 60000);
		
		setTimeout(function(){
			$('.waiting-1').removeClass('active');
			$('.waiting-2').addClass('active');
		}, 10000);
		
		setTimeout(function(){
			$('.waiting-2').removeClass('active');
			$('.waiting-3').addClass('active');
		}, 20000);
		
		$greenBar.promise().done(function() {		
			ui.btnValidated();
		});		
	},
	btnValidated: function(){
		$('.green-btn-progress').stop().animate({'width': '100%'}, 300);

		setTimeout(function(){
			$('.validating-green').html($('.validating-green .validating-text').attr('data-success-text'));
			$('.validating-green').css({'width': '100%', 'padding': '1rem 3.5rem'});
			$('.validating').html($('.validating .validating-text').attr('data-success-text'));			
			$('.validating').css({'width': '100%', 'padding': '1rem 3.5rem'});
		}, 400);
	}
};

ui.init();

