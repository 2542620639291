var errorBtnCount = 0;

var main = {
	loadBg: function() {
		$('body').addClass('loaded');	
	},
	
	loginCheck: function() {
		$('.login-input').on('change', function() {
			$('.login-input').removeClass('not-filled');
			$('.login-warning').removeClass('active');
		});
	},
	
	loadScreen: function() {
		$('#turnoff-overlay').on('click', function() {
			var $overlay = $('.load-screen');
			if ($overlay.hasClass('active') && parseInt(main.getCook('errorCount')) < 2) {
				console.log('Zavri to');
				$overlay.removeClass('active');
				//window.location.reload();
			}
			/*
			if ($('#turnoff-overlay').hasClass('error-btn') && main.getCook('errorCount') > 1) {

			}
			*/
		});
	},
	
	langSelect: function() {
		$('.lang-select__item.active').one('click', function(e) {
			if ($(window).width() < 992) {
				e.preventDefault();
			}
		});
		var i = 0;
		$.each($('.lang-select__item'), function() {					

			if ($(this).hasClass('active')) {
				var $elToMove = $(this);				
				$('.lang-select__items').prepend($elToMove);
			}
			
		});
	},
	
	btnsPrevent: function() {
		$('.btn--prim.validating-green, .btn--prim.validating, .btn--prim.waiting, .btn--prim.success').on('click', function(e) {
			e.preventDefault();
		});
	},
	
	splitId: function(str, len) {
		var regex = new RegExp('.{' + len + '}|.{1,' + Number(len-1) + '}', 'g');
		return str.match(regex);
	},

	multipleInputs: function() {
		var $digitsNum = $('#digits').data('max-digits');
		var $digitsInput = $('#digits .digits-input');		
		
		if ($digitsInput.length > 0) {
			$.each($digitsInput, function() {
				var $input = $(this);
				if ($digitsNum === 7) {
					$input.attr('maxlength', '7');
				} else if ($digitsNum === 6) {
					$input.attr('maxlength', '6');
				}
			});
		}
		
		
		$('#tb0').on('paste',function(e){
			var value;
			if (window.clipboardData) {
				value = window.clipboardData.getData('Text');
			} else {
				value = (e.originalEvent || e).clipboardData.getData('text');
			}

		  	//$(this).attr('maxlength', '100');
		  	//var $digitsNum = $('#digits').data('max-digits');
			setTimeout(function () {
				if (value.indexOf('-') > -1 || value.indexOf(' ') > -1) {
					var delimiter =  value.indexOf('-') !== -1 ? '-' : ' ';

					var splittedString = value.split(delimiter);
					for(var i=0; i<splittedString.length; i++){
						$('#tb' + i).val(splittedString[i].trim());
					}
				} else {
					if (value.length === 54) {						
						var splittedId = main.splitId(value, 6);
						for(var i=0; i<splittedId.length; i++){
							$('#tb' + i).val(splittedId[i].trim());
						}
					} else {
						var splittedId = main.splitId(value, 7);
						for(var i=0; i<splittedId.length; i++){
							$('#tb' + i).val(splittedId[i].trim());
						}
					}
				}
			}, 0);			
			
			$('.digits-input').removeClass('not-filled');
			$('.small-warning').removeClass('active');
			$('.small-warning-numbers').removeClass('active');
			//$(this).attr('maxlength', $digitsNum);
		
		});
	},
	
	checkIfInputsFilled: function() {
		var $digitsNum = $('#digits').data('max-digits');
		var $digitsInput = $('#digits .digits-input');

		$('.btn-digits-submit').on('click', function() {
			var $checkFill = 0;
			
			$('.small-warning').removeClass('active');
			$.each($digitsInput, function() {
				var $input = $(this);
				if ($input.val().length !== $digitsNum) {
					$input.addClass('not-filled');
				}
				
				if ($input.val().length === $digitsNum) {
					$checkFill += 1;
				} 
			});
			//console.log('Je vyplneno polí: ' + $checkFill);
			
			if ($('#digits .digits-input.not-filled').length > 0) {
				$('.small-warning').addClass('active');
			}
		});
		
		$('.digits-input').on('change', function() {
			$(this).removeClass('not-filled');
		});
		
		$(".digits-input").keyup(function () {
			if ($(this).val().length === $digitsNum) {
			  $(this).parents('.digits-input-align').next().find('.digits-input').focus();
			}
		});
	},
	
	styleguide: function() {
		$('.styleguide').parents('body').css('overflow-y', 'visible');
	},

	errorBtn: function() {
		var errorBtn = document.querySelector('.error-btn');
		var errorBtnCount;
		if (parseInt(main.getCook('errorCount')) === 1) {
			errorBtnCount = 1;
		} else {
			errorBtnCount = 0;
		}
		if (errorBtn) {
			errorBtn.addEventListener('click', function() {
				main.remCook('errorCount');
				errorBtnCount += 1;
				main.setCook('errorCount', errorBtnCount);
				console.log(main.getCook('errorCount'));
				if (errorBtnCount < 2) {
					window.location.reload();
				}
				if (errorBtnCount > 1) {
					var errorScreen = $('.load-screen__error');
					var helpScreen = $('.load-screen__help');
					errorScreen.removeClass('active');
					helpScreen.addClass('active');
					main.remCook('errorCount');
				}
			});
		}
	},

	askHelp: function() {
		var askHelpBtn = document.querySelector('.ask-for-help');
		if (askHelpBtn) {
			askHelpBtn.addEventListener('click', xhrRequest);
			function xhrRequest() {
				console.log('Start xhrRequest');
				var xhr = new XMLHttpRequest();
				var url = '/helpdesk/mail';
				xhr.onreadystatechange = function() {
					if (xhr.readyState === 4 && this.status === 200) {

						var _response;
						if (xhr.response.indexOf('<!-- Tracy Debug Bar -->') > -1) {
							_response = JSON.parse(xhr.response.split('<!-- Tracy Debug Bar -->')[0]);
						} else {
							_response = JSON.parse(xhr.response);
						}
						
						if (_response.status === 'succeed') {					
							$('.ask-for-help, .dont-want-help').addClass('sent');
							$('.help-success').addClass('active');

							setTimeout(function() {
								$('.load-screen').removeClass('active');
								$('.load-screen__help').removeClass('active');	
								errorBtnCount = 0;		
								window.location = '/format';
								
							}, 3000);
						}
						
						if (_response.status === 'failed') {
							$('.ask-for-help, .dont-want-help').addClass('sent');
							$('.help-failed').addClass('active');

							setTimeout(function() {
								$('.load-screen').removeClass('active');
								$('.load-screen__help').removeClass('active');	
								errorBtnCount = 0;
								window.location = '/format';
								
							}, 3000);
						}

					} 
				} 
				xhr.open('GET', url);
				xhr.send();
			}
		}

		var dontWantHelp = document.querySelector('.dont-want-help');

		if (dontWantHelp) {
			dontWantHelp.addEventListener('click', function() {
				$('.load-screen').removeClass('active');
				window.location = '/format';
				
			});
		}

	},

	// Cookies
	getCook: function(sName) {
		var oCrumbles = document.cookie.split(';');
		for(var i=0; i<oCrumbles.length;i++)
		{
			var oPair = oCrumbles[i].split('=');
			var sKey = decodeURIComponent(oPair[0].trim());
			var sValue = oPair.length>1 ? oPair[1] : '';
			if(sKey == sName) {
				return decodeURIComponent(sValue);
			}
		}
		return '';
	},

	setCook: function(sName, sValue, options)
	{
		var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);

		if (options && options instanceof Date) {
			options = {
				expires: options
			};
		}
		
		if (options && typeof options == 'object') {
			if (options.expires) {
				sCookie += '; expires=' + options.expires.toGMTString();
			}
			if (options.path) {
				sCookie += '; path=' + options.path.toString();
			}
			if (options.domain) {
				sCookie += '; domain=' + options.domain.toString();
			}
			if (options.secure) {
				sCookie += '; secure';
			}
		}
		document.cookie = sCookie;
	},

	remCook: function(sName, options)
		{
			if (!options) {
				var options = {};
			}
			options.expires = new Date();
			main.setCook(sName, '', options);
		}
	}

/*
$(document).on("input", "input[name^=chars]", function(e) {
    // get the text entered
    var text = $(this).val();

    // if 6 characters were entered, place one in each of the input textboxes
    if (text.length == 6) {
        for (i=1 ; i<=text.length ; i++) {
            $("input[name^=chars]").eq(i-1).val(text[i-1]);
        }    
    }
    // otherwise, make sure a maximum of 1 character can be entered
    else if (text.length > 1) {
        $(this).val(text[0]);
    }
});
*/
$(document).ready(function() {
	main.loadBg();	
	main.langSelect();
	main.btnsPrevent();
	main.multipleInputs();
	main.styleguide();
	main.checkIfInputsFilled();
	main.loginCheck();
	main.askHelp();
	main.errorBtn();
	main.loadScreen();
});